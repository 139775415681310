import SingleAudiobookInformationHeaders from "./SingleAudiobookInformationHeaders";
import SingleAudiobookInformationNonEditable from "./SingleAudiobookInformationNonEditable";
import { useEffect } from "react";

const SingleAudiobookInformation = ({ audiobook }) => {
  useEffect(() => {

  }, [audiobook]);

  return (
    <div
      className="container"
      style={{ textAlign: "center", maxWidth: "100%", }}
    >
      <table width="100%">
        {<tr><td ><div style={{
          textAlign: "left",
          fontSize: "3vh",
          position: "relative",
          top: "2vh",
        }}>{audiobook[0]?.autor}</div><p /></td></tr>}
        <tr><td colSpan={2}><div style={{
          fontSize: "3vh",
          backgroundColor: "#eee",
          position: "relative",
          top: "0.5vh",
          boxShadow: "4px 4px 3px 0px rgba(0,0,0,0.2)",
        }}> <b>{'"' + audiobook[0]?.name + '"'} </b></div></td>
        </tr>
      </table>
      <SingleAudiobookInformationHeaders />
      <SingleAudiobookInformationNonEditable audiobook={audiobook} />
    </div>
  );
};

export default SingleAudiobookInformation;
