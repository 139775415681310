import React, { useEffect, useState, useCallback } from "react";
import { withRouter } from "react-router-dom";
import Spinner from "./Spinner/Spinner";
import PlainContainer from "./PlainContainer";
import SummaryEntry from "./SummaryEntry";
import SummaryEntryLabel from "./SummaryEntryLabel";
import VerticalLine from "./VerticalLine";
import BorrowsTable from "./BorrowsTable";
import getlibraryStats from "../api/getLibraryStats";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from 'notistack';

const LibraryStatsPanel = ({ history }) => {

  const loggedUser = useSelector((state) => state.authentication.user);
  const [loading, setLoading] = useState(true);

  const [period, setPeriod] = useState("M");
  const [stats, setStats] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  const getStats = useCallback(async () => {
    let response;
    response = await getlibraryStats(loggedUser.libAddress, period, start, end);    // legacy naming
    let local_time_offset = new Date().getTimezoneOffset() * 60000;
    setStats(response);
    response.start_date = new Date(new Date(response.start_date).getTime() - local_time_offset).toISOString().split("T")[0];
    response.end_date = new Date(new Date(response.end_date).getTime() - local_time_offset).toISOString().split("T")[0];
    setStats(response);
    setLoading(false);
  }, [period, start, end]);

  useEffect(() => {
    getStats(loggedUser.libAddress, period, start, end);
  }, [period, start, end]);

  const toDate = (datetime) => {
    try {
      return datetime.split("T")[0]

    } catch (e) {
      return datetime;
    };
  }
  return (
    <>
      {
        loading ?
          <div style={{ textAlign: "-webkit-center" }}>
            <Spinner customStyles={{ fontSize: "30px", margin: "40px" }} />
          </div>
          :
          <PlainContainer
            widthInVw={95}
            heightInVh={9}
            customStyles={{
              top: "15vh",
              bottop: "10vh",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%) translateY(-20%)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingBottom: "0px",
              paddingTop: "1%"
            }}
          >
            <h2>Statystyki wypożyczeń</h2>
            <PlainContainer
              widthInVw={95}
              heightInVh={19}

              customStyles={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                marginBotton: "1%",
              }}
            >
              <SummaryEntryLabel text={"Okres"}
                styleOptions={{
                  marginLeft: "1%",
                }}
              />
              <div className="search-select col-2">
                <select
                  className="form-control"
                  name="period"
                  id="period"
                  onChange={(e) => {
                    if (e.target.value === "A") {
                      setStart(stats.start_date);
                      setEnd(stats.end_date);
                    }
                    setPeriod(e.target.value)
                  }}
                >
                  <option value="M">Ten miesiąc</option>
                  <option value="PM">Poprzedni miesiąc</option>
                  <option value="Q">Ten kwartał</option>
                  <option value="PQ">Poprzedni kwartał</option>
                  <option value="Y">Ten rok</option>
                  <option value="PY">Poprzedni rok</option>
                  <option value="YTY">Rok do roku</option>
                  <option value="A">Dowolny okres</option>
                </select>
              </div>

              <VerticalLine
                customStyles={{
                  height: "6vh",
                }}
              />
              <>
                <SummaryEntryLabel text={"Od"}
                  styleOptions={{
                    marginLeft: "1%",
                    marginRight: "1%",
                  }}
                />
                <input readOnly={period !== "A"}
                  type="date"
                  value={toDate(stats.start_date)}
                  className="form-control col-2"
                  name="from"
                  onChange={(e) => {
                    if (new Date(e.target.value) > new Date(end)) {
                      enqueueSnackbar("Data początkowa nie może być późniejsza od daty końcowej");
                      return;
                    }
                    setStart(e.target.value);
                  }}
                />

                <SummaryEntryLabel text={"Do"}
                  styleOptions={{
                    marginLeft: "1%",
                    marginRight: "1%",
                  }} />
                <input
                  readOnly={period !== "A"}
                  type="date"
                  value={toDate(stats.end_date)}
                  className="form-control col-2"
                  name="to"
                  onChange={(e) => {
                    if (new Date(e.target.value) < new Date(start)) {
                      enqueueSnackbar("Data końcowa nie może być wcześniejssza od daty początkowej");
                      return;
                    }
                    setEnd(e.target.value)
                  }}
                />
              </>
              <PlainContainer
                widthInVw={95}
                heightInVh={6}
                customStyles={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  paddingBottom: "0px",
                }}
              >
                <>
                  <SummaryEntryLabel text={"Aktywni czytelnicy"} />
                  <SummaryEntry value={stats.readers} />
                  <VerticalLine
                    customStyles={{
                      height: "6vh",
                    }}
                  />
                  <SummaryEntryLabel text={"Nowi użytkownicy"} />
                  <SummaryEntry value={stats.new_users} />
                  <VerticalLine
                    customStyles={{
                      height: "6vh",
                    }}
                  />

                  <SummaryEntryLabel text={"Wypożyczeń"} />
                  <SummaryEntry value={stats.sum_borrows} />
                  <VerticalLine
                    customStyles={{
                      height: "6vh",
                    }}
                  />
                  <SummaryEntryLabel text={"Tytuły teraz"} />
                  <SummaryEntry value={stats.audiobooks} />
                </>
              </PlainContainer>
            </PlainContainer>
            <BorrowsTable value={stats.borrowsByCategory} />
          </PlainContainer>
      }
    </>
  );
};

export default withRouter(LibraryStatsPanel);
