import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import getAudiobookBorrowHistory from "../api/getAudiobookBorrowHistory";
import { useSelector } from "react-redux";
import TableMain from "./TableMain";
import { getDateFromDateTime, getTimeFromDateTime } from "../utils/time";
import { withRouter } from "react-router-dom";
import Spinner from "./Spinner/Spinner";

const col = [
  { Header: "#", accessor: "idx", columnType: "col-sm-1" },
  {
    Header: "ID",
    accessor: "token_symbol",
    columnType: "col-sm-1",
  },
  {
    Header: "Karta",
    accessor: "lib_card_number",
    columnType: "col-sm-1",
  },
  {
    Header: "Czytelnik",
    accessor: "userName",
    columnType: "col-sm-5",
  },
  {
    Header: "Wypożyczono",
    accessor: "BorrowTime",
    columnType: "col-sm-2",
    Cell: ({ value }) => {
      return getDateFromDateTime(value) + " " + getTimeFromDateTime(value);
    },
  },
  {
    Header: "Oddano",
    accessor: "ReturnTime",
    columnType: "col-sm-2",
    Cell: ({ value }) => {
      return getDateFromDateTime(value) + " " + getTimeFromDateTime(value);
    },
  },
];

const SingleAudiobooksBorrowHistory = ({ history }) => {

  const { id } = useParams()
  const [historyList, setHistoryList] = useState([])
  const [loading, setLoading] = useState(true);

  const loggedUser = useSelector(state => state.authentication.user);
  // const columns = useMemo(() => col, []);
  // const data = useMemo(() => users, []);

  useEffect(() => {
    const getBorrHist = async (tokenSymbol, libAddress) => {
      return await getAudiobookBorrowHistory(tokenSymbol, libAddress, loggedUser.username)
        .then(res => {
          if (res) {
            res = res.map((el, i) => ({ ...el, idx: i + 1 }));
            setHistoryList(res)
          }
          setLoading(false);
        })
    }
    if (loggedUser.business_type === 'audiobook_admin') {
      getBorrHist(id, null)
    } else {
      getBorrHist(id, loggedUser.libAddress)
    }

  }, [])

  const openUser = (userID) => {
    history.push(`${process.env.PUBLIC_URL}/user/${userID}`);
  }

  return (
    <>
      {
        loading ?
          <div style={{ textAlign: "-webkit-center" }}>
            <Spinner customStyles={{ fontSize: "30px", margin: "40px" }} />
          </div>
          :
          historyList.length === 0
            ? <p style={{ height: "35.6vh", fontSize: "30px", lineHeight: "35.6vh" }} className="text-center mt-3">Ta pozycja nie posiada historii wypożyczeń.</p>
            : <TableMain style={{ background: "yellow" }} columns={col} rowsData={historyList} action={openUser} />
      }
    </>
  );
};

export default withRouter(SingleAudiobooksBorrowHistory);
