function splitName(fullName) {
  let firstName = "",
    lastName = "";
  let suffix = "";
  let dualSurnames = ["Le", "le", "von", "de", "van", "Conan", "Greene"];
  let suffixes = ["OP", "OSPPE", "OCSO", "SCJ", "EP", "jr"];

  let words = fullName.trim().split(" ");
  // move suffixes to the end of first name
  for (let j = 0; j < suffixes.length; j++) {
    if (words[words.length - 1] === suffixes[j]) {
      words.pop();
      suffix = " " + suffixes[j];
      break;
    }
  }

  // switch first and last name, if author consists of at least 2 words
  if (words.length >= 2) {
    lastName = words[words.length - 1];
    if (words.length >= 3 && dualSurnames.includes(words[words.length - 2])) {
      lastName = words[words.length - 2] + " " + words[words.length - 1];
      words.pop();
    }
    words.pop();
    firstName = words.join(" ");
  } else {
    firstName = words[0];
    lastName = words[0];
  }
  // remove padding spaces
  firstName = firstName.trim();
  firstName += suffix;
  lastName = lastName.trim();
  return [firstName, lastName];
}

function parseAuthor(author) {
  // items always to be fixed
  let fix = [
    {
      src: "o. Marcin Ciecjanowski OSPPE",
      name: "Ciechanowski o. Marcin OSPPE",
      surname: "Ciechanowski",
    },
    {
      src: "opracowanie zbiorowe",
      name: "Praca zbiorowa",
      surname: "Praca zbiorowa",
    },
    {
      src: "Praca Zbiorowa",
      name: "Praca zbiorowa",
      surname: "Praca zbiorowa",
    },
    {
      src: "Opracowanie zbiorowe",
      name: "Praca zbiorowa",
      surname: "Praca zbiorowa",
    },
  ];

  let skip = ["Biblia 1000-lecia Pallottinum"];
  let remove = ["Opracowanie:", "pod red.", "Wybór i opracowanie:"];
  let asIs = [
    "Praca zbiorowa",
    "Malva B",
    "Animaccord Ltd",
    "Autor nieznany",
    "Annah Viki M.",
    "Izabela A.",
    "Hania L.",
    "LUST authors",
  ];

  let finalName = "";

  if (author.startsWith("- ")) {
    author = author.slice(2);
  }
  if (author === "") {
    return "Autor nieznany";
  }
  // if one word only, do nothing
  if (author.split(" ").length === 1) {
    return author;
  }

  // pseudonyms - copy unchanged
  for (let j = 0; j < asIs.length; j++) {
    if (author === asIs[j]) {
      return asIs[j];
    }
  }
  // Individual items to be fixed
  for (let j = 0; j < fix.length; j++) {
    if (author === fix[j].src) {
      return fix[j].name;
    }
  }

  // Items with prefixes to be skipped
  for (let j = 0; j < skip.length; j++) {
    if (author.startsWith(skip[j])) {
      return author;
    }
  }
  // Prefixes to be stripped
  for (let j = 0; j < remove.length; j++) {
    if (author.startsWith(remove[j])) {
      author = author.slice(remove[j].length);
    }
  }

  // if many authors (separated by ",")
  if (author.includes(",")) {
    // may bemore
    let authors = author.split(",");
    for (var autor of authors) {
      let [aname, asurname] = splitName(autor);
      if (finalName === "") {
        finalName = asurname + " " + aname;
      } else {
        finalName += ", " + asurname + " " + aname;
      }
      return finalName;
    }
  } else {
    // single
    let [aname, asurname] = splitName(author);
    return asurname + " " + aname;
  }
}

export default parseAuthor;
