import { SERVER_URL } from '../utils/constants'

const registerLibEmitent = async (libraryPayload) => {

  const response = await fetch(
    `${SERVER_URL}/registerLibEmitent`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: libraryPayload,
  }
  )
  if (response.status === 200) {
    return response.status
  }
  if (response.status === 400) {
    const { errors } = await response.json()
    return { errors }
  }
  const { emitentId, emitentUserId } = await response.json()

  return ({ emitentId, emitentUserId })
}
export default registerLibEmitent
