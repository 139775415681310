import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import TableHeaders from "./TableHeaders";
import TableBody from "./TableBody";
import TableControls from "./TableControls";
import { withRouter } from "react-router";
import getLibrariesUsersList from "../api/getLibrariesUsersList";
import { useSelector } from "react-redux";
import Spinner from "./Spinner/Spinner";
// import { shortenTableEntry } from "../utils/formatter";

// let users = require("../mock/mock_employees.json");

const LibrariesTable = ({ history }) => {
  const col = [
    {
      Header: "ID",
      accessor: "user_id",
      columnType: "col-sm-1",
    },
    {
      Header: "Biblioteka",
      accessor: "industry",
      columnType: "col-sm-2",
      // Cell: ({ value }) => {
      //   return shortenTableEntry(value, 30);
      // },
    },
    {
      Header: "Nazwa",
      accessor: "username",
      columnType: "col",
    },
    {
      Header: "Email",
      accessor: "email",
      columnType: "col",
    },
    {
      Header: "Adres DHC",
      accessor: "public_address",
      columnType: "col",
    },
    // {
    //   id: "library-edit-button",
    //   columnType: "col",
    //   Header: "",
    //   accessor: "user_id",
    //   Cell: ({ value }) => {
    //     return (
    //       <div className="float-right" >
    //         <Button
    //           text="Edytuj"
    //           onClick={() => editLibrary(value)}
    //           styleOptions={{
    //             padding: "0.35rem 1rem",
    //             fontSize: "0.85rem",
    //           }}
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];

  const [libraries, setLibraries] = useState([]);
  const [loading, setLoading] = useState(true);
  const loggedUser = useSelector((state) => state.authentication.user);

  const getLibraries = async () => {
    let response;
    if (loggedUser.business_type === "audiobook_admin") {
      response = await getLibrariesUsersList();
    } else if (loggedUser.business_type === "library") {
      response = await getLibrariesUsersList(loggedUser.libAddress);
    }
    setLibraries(response);
    setLoading(false);
  };

  useEffect(() => {
    getLibraries();
  }, []);

  const columns = useMemo(() => col, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageOptions,
    state,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    gotoPage,
    pageCount,
  } = useTable(
    { columns, data: libraries, initialState: { pageSize: 16 }, autoResetSortBy: false },
    useSortBy,
    usePagination
  );
  const { pageIndex } = state;

  const libraryClicked = (libraryID) => {
    editLibrary(libraryID)
  };
  const editLibrary = (id) => {
    history.push(`/libraries/edit/${id}`);
  };

  return (
    <div
      className="container"
      {...getTableProps()}
      style={{ maxWidth: "100%", maxHeight: "100%" }}
    >
      {!loading ?
        <>
          <TableHeaders headerGroups={headerGroups} />
          <TableBody
            {...getTableBodyProps()}
            rows={page}
            prepareRow={prepareRow}
            onClick={libraryClicked}
            maxHeight={"67vh"}
          />
          <TableControls
            pageOptions={pageOptions}
            nextPage={nextPage}
            previousPage={previousPage}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            pageIndex={pageIndex}
            gotoPage={gotoPage}
            pageCount={pageCount}
          />
        </>
        : <div style={{ textAlign: "-webkit-center" }}>
          <Spinner customStyles={{ fontSize: "30px", margin: "40px" }} />
        </div>
      }
    </div>
  );
};

export default withRouter(LibrariesTable);
