import { SERVER_URL } from "../utils/constants";

const isActivityTokenSent = async (userId, activityTokenId) => {

  const payload = { userId, activityTokenId };
  const response = await fetch(
    `${SERVER_URL}/isActivityTokenSent`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 400) {
    const { errors } = await response.json();
    return { errors };
  }
  return await response.json();
}

export default isActivityTokenSent