export const LOG_IN_USER = "LOG_IN_USER";
export const LOG_OUT_USER = "LOG_OUT_USER";
export const SHOW_ACTIVE_EMPLOYEES = "SHOW_ACTIVE_EMPLOYEES";
export const SHOW_ALL_EMPLOYEES = "SHOW_ALL_EMPLOYEES";
export const SHOW_DELETED_USERS = "SHOW_DELETED_USERS";
export const DONT_SHOW_DELETED_USERS = "DONT_SHOW_DELETED_USERS";
export const SHOW_AUDIOBOOKS = "SHOW_AUDIOBOOKS";
export const DONT_SHOW_AUDIOBOOKS = "DONT_SHOW_AUDIOBOOKS";
export const SHOW_BORROWS = "SHOW_BORROWS";
export const DONT_SHOW_BORROWS = "DONT_SHOW_BORROWS";
