import React from "react";
import Background from "../components/Background";
import NavTopBar from "../components/NavTopBar";
import SummaryTable from "../components/SummaryTable";
import { connect } from "react-redux";

class DashboardView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {

    return (
      <>
        <NavTopBar />
        <Background height="45vh" />
        <SummaryTable />
        {/* <AwaitingUsersFragment /> */}
        {/* <ActiveUsersFragment /> */}
        {/* <AudiobooksListFragment /> */}
      </>
    );
  }
}

// export default DashboardView;
const mapStoreToProps = ({ authentication }) => ({
  user: authentication.user,
});

// const mapDispatchToProps = (dispatch) => ({
//   actions: createThunkActions(dispatch, {

//   }),
// });

export default connect(mapStoreToProps, null)(DashboardView);
