import { withRouter } from "react-router";
import Background from "../components/Background";
import Button from "../components/Button";
import PublishersTable from "../components/PublishersTable";
import NavTopBar from "../components/NavTopBar";
import OverTableButtons from "../components/OverTableButtons";
import PlainContainer from "../components/PlainContainer";
import Footer from "../components/Footer";

const RegisterPublisherView = ({ history }) => {
  return (
    <>
      <NavTopBar />
      <Background height="45vh" />
      <OverTableButtons
        widthInVw={95}
        heightInVh={4.1}
        customStyles={{ top: "15.5vh" }}
      >
        <Button
          text={
            <div style={{
              display: "flex",
              color: "#f4f4f4"
            }}>
              Dodaj wydawcę
            </div>
          }
          styleOptions={{
            float: "right",
            backgroundColor: "white",
            backgroundImage: "none",
            height: "100%",
            color: "black",
          }}
          onClick={() => history.push("/publishers/add")}
        />
      </OverTableButtons>
      <PlainContainer
        widthInVw={95}
        heightInVh={'auto'}
        customStyles={{
          top: "19vh",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          paddingBottom: "0px",
        }}
      >
        <PublishersTable />
      </PlainContainer>
      <Footer />
    </>
  );
};

export default withRouter(RegisterPublisherView);
