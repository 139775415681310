import * as types from "../redux/types/types";

const state = (() => {
  const cachedTokenType = localStorage.getItem("tokenTypeFilter");
  if (cachedTokenType) {
    return JSON.parse(cachedTokenType);
  } else {
    localStorage.setItem("usersTokenTypeFilter", false);
    return false;
  }
})();

const initialStore = {
  state,
};

const showTokenType = (store = initialStore, action) => {
  switch (action.type) {
    case types.SHOW_AUDIOBOOKS:
      localStorage.setItem("userTokenTypeFilterAudiobooks", false);
      return { ...store, state: false };
    case types.DONT_SHOW_AUDIOBOOKS:
      localStorage.setItem("userTokenTypeFilterAudiobooks", true);
      return { ...store, state: true };
    case types.SHOW_BORROWS:
      localStorage.setItem("userTokenTypeFilterBorrows", false);
      return { ...store, state: false };
    case types.DONT_SHOW_BORROWS:
      localStorage.setItem("userTokenTypeFilterBorrows", true);
      return { ...store, state: true };
    default:
      return store;
  }
};

export default showTokenType;
