import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TopBarNavigationElement from "./TopBarNavigationElement";

const TopBarNavigation = ({ location }) => {
  const path = location.replace(`${process.env.PUBLIC_URL}/`, "");
  const user = useSelector(state => state.authentication.user)
  return (
    <div
      style={{
        position: "absolute",
        left: "9vw",
        top: 24,
        display: "flex",

      }}
    >
      {(user.business_type !== "publisher" && user.business_type !== "publisherEmployee") ?
        <Link to={`${process.env.PUBLIC_URL}/users`} style={{ textDecoration: "none", }}>
          <TopBarNavigationElement
            isFirst
            status={path.startsWith("users") ? "active" : null}
          >
            Czytelnicy
          </TopBarNavigationElement>
        </Link> : null
      }
      {(user.business_type !== "publisher" && user.business_type !== "publisherEmployee") ?
        <Link to={`${process.env.PUBLIC_URL}/audiobooks`} style={{ textDecoration: "none", }}>
          <TopBarNavigationElement
            status={path.startsWith("audiobooks") ? "active" : null}
          >
            Audiobooki
          </TopBarNavigationElement>
        </Link> : null
      }
      {
        (user.business_type === "publisher" || user.business_type === "publisherEmployee") ?
          <Link to={`${process.env.PUBLIC_URL}/tokens`} style={{ textDecoration: "none", }}>
            <TopBarNavigationElement
              status={path.startsWith("tokens") ? "active" : null}
            >
              Tokeny
            </TopBarNavigationElement>
          </Link>
          : null
      }
      {
        (user.business_type === "publisher" || user.business_type === "publisherEmployee") ?
          <Link to={`${process.env.PUBLIC_URL}/transactions`} style={{ textDecoration: "none", }}>
            <TopBarNavigationElement
              status={path.startsWith("transactions") ? "active" : null}
            >
              Transakcje
            </TopBarNavigationElement>
          </Link>
          : null
      }
      {
        user.business_type === "audiobook_admin" ?
          <Link to={`${process.env.PUBLIC_URL}/publishers`} style={{ textDecoration: "none", }}>
            <TopBarNavigationElement
              status={path.startsWith("publishers") ? "active" : null}
            >
              Wydawcy
            </TopBarNavigationElement>
          </Link>
          : null
      }
      {
        user.business_type === "audiobook_admin" ?
          <Link to={`${process.env.PUBLIC_URL}/libraries`} style={{ textDecoration: "none", }}>
            <TopBarNavigationElement
              status={path.startsWith("libraries") ? "active" : null}
            >
              Biblioteki
            </TopBarNavigationElement>
          </Link>
          : null
      }

      {
        user.business_type === "audiobook_admin" || user.business_type === "library" ?
          <Link to={`${process.env.PUBLIC_URL}/librarystats`} style={{ textDecoration: "none", }}>
            <TopBarNavigationElement
              status={path.startsWith("librarystats") ? "active" : null}
            >
              Statystyki
            </TopBarNavigationElement>
          </Link>
          : null
      }

      {
        user.business_type === "audiobook_admin" || user.business_type === "library" || user.business_type === "publisher" ?
          <Link to={`${process.env.PUBLIC_URL}/employees-library`} style={{ textDecoration: "none", }}>
            <TopBarNavigationElement
              isLast
              status={path.startsWith("employees") ? "active" : null}
            >
              Pracownicy
            </TopBarNavigationElement>
          </Link>
          : null
      }



    </div >
  );
};

export default TopBarNavigation;
