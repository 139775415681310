import React, { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import { useTable, useSortBy, usePagination } from "react-table";
import TableHeaders from "./TableHeaders";
import TableBody from "./TableBody";
import TableControls from "./TableControls";
import { shortenTableEntry } from "../utils/formatter";
import { useSelector } from "react-redux";
import getAudiobooksByBuisnessType from "../api/getAudiobooksByBuisnessType";
import getAudioBooksPurchaseLink from "../api/getAudioBooksPurchaseLink";
import Button from "./Button";

import OverTableButtons from "../components/OverTableButtons";
import PlainContainer from "../components/PlainContainer";
import TableSearch from "../components/TableSearch";
import LibraryFilter from "./LibraryFilter";
import Spinner from "./Spinner/Spinner";
import { enqueueSnackbar } from "notistack";
import { SEARCH_DEBOUNCE_TIME } from "../utils/constants";
import { debounce } from "../utils/debounce";

//let users = require("../mock/mock_audiobooks_view.json");

const col = [
  { Header: "#", accessor: "idx", columnType: "col-sm-1" },
  { Header: "ID", accessor: "token_symbol", columnType: "col-sm-1" },
  {
    Header: "Tytuł",
    accessor: "name",
    columnType: "col-sm-4",
    Cell: ({ value }) => {
      const decodedValue = decodeURIComponent(value).replace(/\+/g, " ");
      return shortenTableEntry(decodedValue, 45);
    },
  },
  {
    Header: "Autor",
    accessor: "autor",
    columnType: "col-sm-3",
    Cell: ({ value }) => {
      return decodeURIComponent(value).replace(/\+/g, " ");
    },
  },
  { Header: "Ilość", accessor: "RazemInOut", columnType: "col-sm-1" },
  {
    Header: "Półka",
    accessor: "Ballance",
    columnType: "col-sm-1",
  },
  // {
  //   Header: "Oczekujący",
  //   accessor: "amountWaiting",
  //   columnType: "col-sm-1",
  // },
  {
    Header: "Wypoż.",
    accessor: "sumaBorrow",
    columnType: "col-sm-1",
  },
];

const AudiobooksTable = ({ history }) => {

  const loggedUser = useSelector(state => state.authentication.user)
  const [audiobooks, setAudiobooks] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [chosenLibrary, setChosenLibrary] = useState("none");
  const [filteredData, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const getAudiobooks = async () => {
      setLoading(true);
      if (loggedUser.business_type === 'audiobook_admin') {
        if (chosenLibrary === "none") {
          await getAudiobooksByBuisnessType()
            .then(res => {
              if (!res) {
                setLoading(false);
                return;
              }
              res = res.map((el, i) => ({ ...el, idx: i + 1 }));
              setAudiobooks(res);
              setFiltered(res);
              setLoading(false);
            })
        }
        else {
          await getAudiobooksByBuisnessType(chosenLibrary)
            .then(res => {
              if (!res) {
                setLoading(false);
                return;
              }
              res = res.map((el, i) => ({ ...el, idx: i + 1 }));
              setAudiobooks(res);
              setFiltered(res);
              setLoading(false);
            })
        }

      } else {
        await getAudiobooksByBuisnessType(loggedUser.libAddress, loggedUser.username)
          .then(res => {
            if (!res) {
              setLoading(false);
              return;
            }

            res = res.map((el, i) => ({ ...el, idx: i + 1 }));

            setAudiobooks(res);
            setFiltered(res);
            setLoading(false);
          })
      }
    }
    getAudiobooks()


  }, [chosenLibrary, loggedUser.business_type, loggedUser.libAddress, loggedUser.username])


  const columns = useMemo(() => col, []);
  const data = useMemo(() => filteredData, [filteredData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageOptions,
    state,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    gotoPage,
    pageCount,
  } = useTable(
    { columns, data, initialState: { pageSize: 18 }, autoResetSortBy: false },
    useSortBy,
    usePagination
  );
  const { pageIndex } = state;

  const openAudiobook = (audiobookID) => {
    history.push(`${process.env.PUBLIC_URL}/audiobook/${audiobookID}`);
  };
  const handleChange = (event) => { setSearchInput(event.target.value) };
  const changeFilter = (event) => { setChosenLibrary(event.target.value) };

  useEffect(() => {
    const filterTransactions = () => {
      if (searchInput.length > 2) {

        let results = audiobooks.filter((value) => {
          if (
            value.name &&
            value.token_symbol &&
            value.autor
          ) {
            // value.name = decodeURIComponent(value.name).replace(/\+/g, " ");
            // value.autor = decodeURIComponent(value.autor).replace(/\+/g, " ");

            return (
              value.token_symbol.includes(searchInput) ||
              value.name.toLowerCase().includes(searchInput.toLowerCase()) ||
              value.autor.toLowerCase().includes(searchInput.toLowerCase()));
          } else {
            return null;
          }
        });

        setFiltered(results);

      } else {
        setFiltered(audiobooks);
      }
    };
    debounce(filterTransactions, SEARCH_DEBOUNCE_TIME)();
  }, [searchInput, audiobooks]);


  return (
    <div>
      <OverTableButtons
        widthInVw={95}
        heightInVh={4.1}
        customStyles={{ top: "15.5vh" }}
      >
        <TableSearch
          name="searchInputField"
          value={searchInput || ""}
          onChange={handleChange}
        />
        {
          (loggedUser && loggedUser.business_type === "library") ?
            <Button
              text="Zakup Audiobooków"
              styleOptions={{
                display: "inline",
                padding: "0.25rem 0.5rem",
                marginLeft: "1rem",
                minWidth: "100px"
              }}
              isDisabled={!canNextPage}
              onClick={async () => await purchaseAudiobooks(loggedUser)}
            /> :
            null
        }
        {
          (loggedUser.business_type === 'audiobook_admin') ?
            <LibraryFilter
              onChange={changeFilter}
            /> :
            null
        }
      </OverTableButtons>
      <PlainContainer
        widthInVw={95}
        heightInVh={'auto'}

        customStyles={{
          top: "19vh",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          paddingBottom: "0px",
        }}
      >
        <div
          className="container"
          {...getTableProps()}
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        >
          {!loading ?
            <>
              <TableHeaders headerGroups={headerGroups} />
              <TableBody
                {...getTableBodyProps()}
                rows={page}
                prepareRow={prepareRow}
                onClick={openAudiobook}
                maxHeight={"67vh"}
              />
              <TableControls
                pageOptions={pageOptions}
                nextPage={nextPage}
                previousPage={previousPage}
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                pageCount={pageCount}
              />
            </>
            : <div style={{ textAlign: "-webkit-center" }}>
              <Spinner customStyles={{ fontSize: "30px", margin: "40px" }} />
            </div>
          }
        </div>
      </PlainContainer>
    </div>
  );
};

export default withRouter(AudiobooksTable);

export async function purchaseAudiobooks(loggedUser) {
  // open new website 
  try {
    const data = await getAudioBooksPurchaseLink({
      address: loggedUser.libAddress,
      jwtToken: loggedUser.jwtToken,
    });
    window.open(data.link);
  } catch (error) {
    if (error.message === "Session expired") {
      enqueueSnackbar("Sesja wygasła. Przeloguj się", { variant: "error" });
    }

  }

}
