/* eslint-disable max-len */
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import LoginView from "./pages/LoginView";
import DashboardView from "./pages/DashboardView";
import RegisterView from "./pages/RegisterView";
import ProtectedRoute from "./components/ProtectedRoute";
import UsersView from "./pages/UsersView";
import EmployeesView from "./pages/EmployeesView";
import LibrariesView from "./pages/LibrariesView";
import PublishersView from "./pages/PublishersView";
import PublihserFormView from "./pages/PublisherFormView"
import TokensView from "./pages/TokensView";
import TransactionsView from "./pages/TransactionsView";
import UserView from "./pages/UserView";
import AudiobooksView from "./pages/AudiobooksView";
import AudiobookView from "./pages/AudiobookView";
import LibrariesFormView from "./pages/LibrariesFormView";
import EmployeesFormView from "./pages/EmployeesFormView";
import LibraryStatsview from "./pages/LibraryStatsView";

const Router = (props) => (
  <BrowserRouter>
    <Switch>
      <Route path={`/`} component={LoginView} exact />
      <ProtectedRoute path={`/dashboard`} component={DashboardView} />
      <Route path={`/register`} component={RegisterView} />
      {/* <Route path={`/users`} component={UsersView} /> */}
      <ProtectedRoute path={`/dashboard`} component={DashboardView} />
      <ProtectedRoute path={`/users`} component={UsersView} />
      <ProtectedRoute path={`/publishers`} component={PublishersView} />
      <ProtectedRoute path={`/publishers/edit/:id`} component={PublihserFormView} />
      <ProtectedRoute path={`/tokens`} component={TokensView} />
      <ProtectedRoute path={`/transactions`} component={TransactionsView} />
      <ProtectedRoute path={`/employees-library`} component={EmployeesView} exact />
      <ProtectedRoute path={`/employees-library/add`} component={EmployeesFormView} />
      <ProtectedRoute path={`/employees-library/edit/:id`} component={EmployeesFormView} />
      <ProtectedRoute path={`/libraries`} component={LibrariesView} exact />
      <ProtectedRoute path={`/libraries/edit/:id`} component={LibrariesFormView} />
      <ProtectedRoute path={`/libraries/add`} component={LibrariesFormView} />
      <ProtectedRoute path={`/librarystats`} component={LibraryStatsview} />
      {/* <Route path={`/register`} component={RegisterView} /> */}
      <Route path={`/user/:id`} component={UserView} />
      <Route path={`/audiobooks`} component={AudiobooksView} />
      <Route path={`/audiobook/:id`} component={AudiobookView} />
    </Switch>
  </BrowserRouter>
);

const mapStateToProps = (state) => ({
  isAuthenticated: state.userLogin,
  doesUserHaveEmitentId: state.emitentId,
});

export default connect(mapStateToProps)(Router);
