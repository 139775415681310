import React, { useState, useEffect } from 'react'
import Button from "../Button";
import { generatePassword } from "../../utils/generators"
import { NOTIFICATION_TYPES } from "../../utils/constants";
import getEmployeeByID from '../../api/getEmployeeByID';
import { getDateFromDateTime, getTimeFromDateTime } from '../../utils/time'
import { useForm } from "react-hook-form";

import { enqueueSnackbar } from 'notistack';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import registerLibEmployee from '../../api/registerLibEmployee';
import '../../icons/OpenSans-Regular-normal';
import jsPDF from "jspdf";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import LibrarySearch from '../SearchComponent/SearchLibrary'

import QRCode from 'qrcode'

const userSchema = yup.object().shape({
    firstname: yup.string().required('Podaj imię użytkownika.'),
    surname: yup.string().required('Podaj nazwisko użytkownika.'),
    username: yup.string().required('Podaj unikalną nazwę użytkownika.'),
    email: yup.string().required('Podaj unikalny email.').email()
});

const RegisterEmployee = ({ props }) => {
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [firstname, setFirstname] = useState('')
    const [surname, setSurname] = useState('')

    const [selectedLibrary, setSelectedLibrary] = useState('')
    // const [librariesList, setLibrariesList] = useState(null)

    const [showLibraryChooser, toggleLibraryChooser] = useState(false)

    const loggedUser = useSelector(state => state.authentication.user)
    const { id: chosenEmployeeID } = useParams();

    // const [libraryInfo, setLibraryInfo] = useState(null)

    const passGenerator = () => {
        let newPass = generatePassword();
        setPassword(newPass);
    }

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(userSchema),
    });


    const print = (data) => {
        let doc = new jsPDF();
        let date = new Date();
        let stringToPDF =
            `Dane do logowania:\n\n${data.firstname} ${data.surname}\n\nemail: ${data.email}\nlogin: ${data.username}\nhasło: ${data.password}\n
Data rejestracji: ${getDateFromDateTime(date)}\nGodzina: ${getTimeFromDateTime(date)}`;

        QRCode.toDataURL(stringToPDF)
            .then(url => {

                doc.addImage(url, 120, 0)

                doc.setFont("OpenSans-Regular");
                doc.setFontSize(14);
                doc.text(stringToPDF, 20, 20);
                doc.save(data.username + "-dane-do-logowania.pdf");
            })
            .catch(err => {
                console.error(err)
            })


    };


    const onSubmit = submittedData => {
        const data = { ...submittedData, id: chosenEmployeeID, libId: loggedUser.libId, isPublisher: loggedUser.business_type === "publisher" ? true : false };
        if (data.password === "") {
            enqueueSnackbar(`Hasło nie może być puste`);
            return;
        }
        registerLibEmployee(data)
            .then(result => {
                if (result === 200) {
                    enqueueSnackbar(NOTIFICATION_TYPES.EMPLOYEE_ADDED)
                    setSelectedLibrary('');
                    setEmail('');
                    setUsername('');
                    setFirstname('');
                    setSurname('');
                    setPassword('');
                    print(data)
                } else {
                    if (result.error.errors[0].message === "email_UNIQUE must be unique") {
                        showSnackbar(`Ten adres e-mail jest już używany`)
                    } else {
                        //    showSnackbar(`error = ${JSON.stringify(result)}`)
                    }
                }
            }
            )
    }

    function showSnackbar(message) {
        enqueueSnackbar(message)
    }


    // useEffect(() => {
    //     async function getLibsList() {
    //         let response = await getLibrariesList()
    //         setLibrariesList(response)
    //     }
    //     getLibsList();
    // }, []);

    useEffect(() => {
        async function getEmployee() {
            if (chosenEmployeeID !== undefined) {
                let response = await getEmployeeByID(chosenEmployeeID)
                setSelectedLibrary({ industry: response.industry, publicKey: response.public_address });
                setEmail(response.email);
                setFirstname(response.firstname);
                setSurname(response.surname);
                setUsername(response.username);
                setPassword(response.password);
            }
        }
        getEmployee()
    }, [chosenEmployeeID]);

    const openLibraryChooser = (e) => {
        e.preventDefault();
        toggleLibraryChooser(true);
    }

    const closeLibraryChooser = () => {
        toggleLibraryChooser(false);
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="container mt-2 pl-3 pr-3">

                <div className="row justify-content-around">

                    <div className="col-6">

                        <div className="form-group">
                            {/* <label htmlFor="">Wybierz bibliotekę {errors.libraryName && <span className="text-danger">({errors.libraryName.message})</span>}</label>
                    
                    <select className="form-control" name="publicKey" onChange={(e) => setSelectedLibrary(e.target.value)} ref={register} value={selectedLibrary}>
                    {
                        (loggedUser.business_type === "audiobook_admin") 
                        ?
                        (librariesList ? librariesList.map(library => (<option key={library.publicKey} value={library.publicKey}>{library.libraryName}</option>)) : null)
                        :
                        (loggedUser.business_type === "library") ? (<option key={loggedUser.libAddress} value={loggedUser.libAddress}>{loggedUser.industry}</option>) : null
                    }
                    </select>  */}


                            {
                                (loggedUser.business_type === "audiobook_admin")
                                    ?
                                    <>
                                        <button className="btn btn-outline-secondary" onClick={e => openLibraryChooser(e)}>Szukaj biblioteki...</button>
                                        {
                                            selectedLibrary && (
                                                <>
                                                    <p>
                                                        Wybrana biblioteka: {selectedLibrary.industry}
                                                    </p>
                                                    <input
                                                        name="libraryName"
                                                        id="libraryName"
                                                        type="hidden"
                                                        value={selectedLibrary ? selectedLibrary.industry : null}
                                                        readOnly={true}
                                                        ref={register} />
                                                    <input
                                                        name="publicKey"
                                                        id="publicKey"
                                                        type="hidden"
                                                        value={selectedLibrary ? selectedLibrary.publicKey : loggedUser.libAddress}
                                                        readOnly={true}
                                                        ref={register} />
                                                </>
                                            )
                                        }
                                    </>
                                    :
                                    (
                                        <>
                                            <p>
                                                Wybrana biblioteka: {loggedUser.industry}
                                            </p>
                                            <input
                                                name="publicKey"
                                                id="publicKey"
                                                type="hidden"
                                                value={loggedUser.libAddress}
                                                readOnly={true}
                                                ref={register} />
                                            <input
                                                name="libraryName"
                                                id="libraryName"
                                                type="hidden"
                                                value={loggedUser.industry}
                                                readOnly={true}
                                                ref={register} />
                                        </>
                                    )
                            }
                        </div>

                        <div className="form-group mb-3">
                            <label htmlFor="email">Adres email {errors.email && <span className="text-danger">({errors.email.message})</span>}</label>
                            <input className="form-control" type="email" name="email" onChange={(e) => setEmail(e.target.value)} ref={register} value={email} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="username">Nazwa użytkownika {errors.username && <span className="text-danger">({errors.username.message})</span>}</label>
                            <input className="form-control" type="text" name="username" onChange={(e) => setUsername(e.target.value)} ref={register} value={username} />
                        </div>
                    </div>

                    <div className="col-6" >
                        <div className="row mt-5"></div>
                        <div className="row mt-4"></div>
                        <div className="form-group mt-5">
                            <label htmlFor="firstname">Imię {errors.firstname && <span className="text-danger">({errors.firstname.message})</span>}</label>
                            <input className="form-control" type="text" name="firstname" onChange={(e) => setFirstname(e.target.value)} ref={register} value={firstname} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="surname">Nazwisko {errors.surname && <span className="text-danger">({errors.surname.message})</span>}</label>
                            <input className="form-control" type="text" name="surname" onChange={(e) => setSurname(e.target.value)} ref={register} value={surname} />
                        </div>
                    </div>
                    <div className="form-group mt-0">
                        <label htmlFor="password">Hasło {errors.password && <span className="text-danger">({errors.password.message})</span>}</label>
                        <div className="row">
                            <div className="col-9">
                                <input className="form-control" type="text" name="password" value={password} ref={register} readOnly={true} />
                            </div>
                            <div className="col-3">
                                <Button text="Generuj" onClick={() => passGenerator()} />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row d-flex justify-content-center">
                    <Button type="submit" text="Zapisz pracownika i generuj podsumowanie" />
                </div>
            </form>

            <LibrarySearch
                show={showLibraryChooser}
                cancelAction={closeLibraryChooser}
                setLibraryAction={setSelectedLibrary} />

        </>
    );

}

export default RegisterEmployee;