import React, { useEffect, useMemo, useState, useCallback, useRef } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import TableHeaders from "./TableHeaders";
import TableBody from "./TableBody";
import TableControls from "./TableControls";
import Button from "./Button";
import { withRouter } from "react-router";
import getTransactionsList from "../api/getTransactionsList";
import { useSelector } from "react-redux";
import Spinner from "./Spinner/Spinner";
import OverTableButtons from "../components/OverTableButtons";
import TableSearch from "../components/TableSearch";
import PlainContainer from "../components/PlainContainer";
import { getDateTime, getShortDateTime, getShortAddresName } from "../utils/formatter";
import { Tooltip } from 'react-tooltip'
import { SEARCH_DEBOUNCE_TIME } from "../utils/constants";
import { debounce } from "../utils/debounce";

const getTrTypeFormat = (value) => {
  let toolTip = "", letter = "", color = "";
  switch (value) {
    case "s":  //
      toolTip = "Przesłano";
      letter = "P";
      color = "green";
      break;
    case "r":
      toolTip = "Zwrot";
      letter = "Z";
      color = "red";
      break;
    case "c":
      toolTip = "Utworzono nowy token";
      letter = "U";
      color = "blue";
      break;
    case "b":
      toolTip = "Wypożyczono";
      letter = "W";
      color = "green";
      break;
    default:
      toolTip = "Błąd";
      letter = "?";
      color = "purple";
      break;
  }
  return { toolTip, letter, color };
}
const hashCode = (str) => {
  return str.split("").reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
};

const intToRGB = (i) => {
  const c = (i & 0x00FFFFFF)
    .toString(16)
    .toUpperCase();
  return "00000".substring(0, 6 - c.length) + c;
};
const darkenRGB = (rgb) => {
  let darkened = rgb - 0x090909;
  if (darkened < 0) darkened = 0;
  return darkened;
}

const TransactionsTable = () => {
  const col = useMemo(() => [
    {
      Header: "Czas",
      accessor: "sendTime",
      columnType: "col-sm-1",
      Cell: ({ value }) => {
        const { date, time } = getShortDateTime(value);
        return <span
          data-tooltip-id="timedate-tooltip"
          data-tooltip-content={date}
        >
          {time}
          <Tooltip id="timedate-tooltip" />
        </span>
      },
    },
    // {
    //   Header: "Tr",
    //   accessor: "transaction_id",
    //   columnType: "col-sm-1",
    //   Cell: ({ value }) => { return "" + value; }
    // },
    {
      Header: "Symbol",
      accessor: "token_symbol",
      columnType: "col-sm-1",
    },
    {
      Header: "Format",
      accessor: "format",
      columnType: "col-sm-1",
      Cell: ({ value }) => {
        switch (value) {
          case "AUDIOB":
            value = "Audiobook";
            break;
          default:
            break;
        }
        return value;
      }
    },
    {
      Header: "Typ Tr",
      accessor: "type",
      columnType: "col-sm-1",

      Cell: ({ value }) => {
        let { toolTip, letter, color } = getTrTypeFormat(value);
        return (
          <div>
            <span
              data-tooltip-id="type-tooltip"
              data-tooltip-content={toolTip}
              style={{
                color: color,
                fontWeight: "bold",
              }}
            >
              {letter}
            </span>
            <Tooltip id="type-tooltip" />
          </div>
        );
      }
    },

    {
      Header: "Z",
      accessor: "userFrom",
      columnType: "col-sm-2",
      Cell: ({ value, row }) => {
        let toolTip = value;
        value = getShortAddresName(value);

        const color = `#${intToRGB(darkenRGB(hashCode(value)))}`;
        return (
          <span
            data-tooltip-id={toolTip ? "from-tooltip" : undefined}
            data-tooltip-content={toolTip}
            style={{ color }}
          >
            {(row.values.type !== "c") ? value : ""}
            {toolTip && <Tooltip id="from-tooltip" />}
          </span>
        );
      }
    },
    {
      Header: "Do",
      accessor: "userTo",
      columnType: "col-sm-2",
      Cell: ({ value }) => {
        let toolTip = value;
        value = getShortAddresName(value);
        const color = `#${intToRGB(darkenRGB(hashCode(value)))}`;
        return (
          <span
            data-tooltip-id={toolTip ? "to-tooltip" : undefined}
            data-tooltip-content={toolTip}
            style={{ color }}
          >
            {value}
            {toolTip && <Tooltip id="to-tooltip" />}
          </span>
        );
      }
    },

    {
      Header: "Tytuł.",
      accessor: "name",
      columnType: "col-sm-3",
      Cell: ({ value }) => {
        let toolTip = value;
        if (value.length > 50)
          value = value.slice(0, 50) + "...";
        return <div>
          <span
            data-tooltip-id="type-tooltip"
            data-tooltip-content={toolTip}
          >
            {value}
          </span>
          <Tooltip id="type-tooltip" />
        </div>
      }
    },
    {
      Header: "Ilość",
      accessor: "amount",
      columnType: "col-sm-1",
    }
  ], []);

  const [transactions, setTransactions] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFiltered] = useState([]);

  const [loading, setLoading] = useState(true);
  const loggedUser = useSelector((state) => state.authentication.user);

  const [progress, setProgress] = useState(0); // State for progress bar

  const refreshInterval = 10000; // Refresh every 10 seconds
  const progressInterval = 500; // Update progress every 100ms
  const intervalRef = useRef(null);
  const progressIntervalRef = useRef(null);

  const getTransactions = useCallback(async () => {
    let response;
    response = await getTransactionsList(loggedUser.libAddress);    // legacy naming
    setTransactions(response);
    setFiltered(response);
    setLoading(false);
  }, [loggedUser.libAddress]);

  const start_refresh = useCallback(() => {
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      getTransactions();
    }, refreshInterval); // Refresh every 10 seconds

    if (progressIntervalRef.current) return;
    progressIntervalRef.current = setInterval(() => {
      setProgress((prev) => (prev < 100 ? prev + (100 / (refreshInterval / progressInterval)) : 0));
    }, progressInterval);
    setProgress(0);
  }, [getTransactions]);

  const stop_refresh = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
      progressIntervalRef.current = null;

    }
    setProgress(100);
  }, [])

  useEffect(() => {
    console.log("On load");
    getTransactions();

    start_refresh();

    return () => {
      stop_refresh();
    }
  }, [getTransactions, start_refresh, stop_refresh]);

  const columns = useMemo(() => col, [col]);
  const data = useMemo(() => filteredData, [filteredData]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageOptions,
    state,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    gotoPage,
    pageCount,
  } = useTable(
    {
      columns, data, initialState: {
        pageSize: 16
      },
      autoResetSortBy: false
    },
    useSortBy,
    usePagination
  );
  const { pageIndex } = state;
  useEffect(() => {
    if (pageIndex > 0) {
      stop_refresh();
    } else {
      start_refresh();
    }
  }, [pageIndex, start_refresh, stop_refresh]);

  const handleChange = (event) => {
    setSearchInput(event.target.value);
  };

  useEffect(() => {

    const filterTransactions = () => {
      if (searchInput.length > 2) {
        stop_refresh();
        let pattern = searchInput.toLocaleLowerCase();
        let results = transactions.filter((value) => {
          if (value.name !== undefined) {
            return (
              getDateTime(value.sendTime).includes(pattern) ||
              value.transaction_id.toString().includes(pattern) ||
              value.token_symbol.toLowerCase().includes(pattern) ||
              value.name.toLowerCase().includes(pattern) ||
              value.userFrom.toLowerCase().includes(pattern) ||
              value.userTo.toLowerCase().includes(pattern)
            );
          } else {
            return null;
          }
        });
        setFiltered(results);
      } else {
        start_refresh();
        setFiltered(transactions);
      }
    };

    debounce(filterTransactions, SEARCH_DEBOUNCE_TIME)();

  }, [searchInput, transactions, start_refresh, stop_refresh]);

  return (
    <div>
      <OverTableButtons
        widthInVw={95}
        heightInVh={4.1}
        customStyles={{ top: "15.5vh" }}
      >
        <div>
          <Button
            text="Odśwież"
            isDisabled={false}
            styleOptions={{
              float: "right",
              boxShadow: "2px 4px 18px -15px rgba(255,0,0,0.31)",
              background: `linear-gradient(to right, darkblue ${progress}%, transparent ${progress}%)`,
              border: "2px solid black",
              borderRadius: "4px",
            }}
            onClick={() => {
              setProgress(0);
              setLoading(true);
              getTransactions();
            }}
          />
        </div>
        <TableSearch
          name="searchInputField"
          value={searchInput || ""}
          onChange={handleChange}
        />
      </OverTableButtons>
      <PlainContainer
        widthInVw={95}
        customStyles={{
          top: "19vh",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          paddingBottom: "0px",
        }}
      >
        <div
          className="container"
          {...getTableProps()}
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        >
          {!loading ? (
            <>
              <TableHeaders headerGroups={headerGroups} />
              <TableBody
                {...getTableBodyProps()}
                rows={page}
                prepareRow={prepareRow}
                onClick={(row) => { }}
              />
              <TableControls
                pageOptions={pageOptions}
                nextPage={nextPage}
                previousPage={previousPage}
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                pageCount={pageCount}
              />
            </>
          ) : (
            <div style={{ textAlign: "-webkit-center" }}>
              <Spinner customStyles={{ fontSize: "30px", margin: "40px" }} />
            </div>
          )}
        </div>
      </PlainContainer>
    </div>
  );
};

export default withRouter(TransactionsTable);
