import { withRouter } from "react-router";
import Background from "../components/Background";
import NavTopBar from "../components/NavTopBar";
import Footer from "../components/Footer";
import LibraryStatsPanel from "../components/LibraryStatsPanel";

const LibraryStatsView = ({ history }) => {
  return (
    <>
      <NavTopBar />
      <Background height="45vh" />
      <LibraryStatsPanel />
      <Footer />
    </>
  );
};

export default withRouter(LibraryStatsView);
