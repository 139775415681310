import { LOCAL_SERVER_URL, SERVER_URL } from '../utils/constants'

const getLibrariesList = async () => {

  const response = await fetch(
    `${SERVER_URL}/getPublishersList`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    }
  }
  )
  if (response.status === 400) {
    const { errors } = await response.json()
    return { errors }
  }
  return await response.json()
}
export default getLibrariesList
