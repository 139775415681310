import { SERVER_URL } from "../utils/constants";

const getLibraryStats = async (address, period, start, end) => {
  const response = await fetch(
    `${SERVER_URL}/getLibraryStats?address=${address}&period=${period}&start=${start}&end=${end}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 400) {
    const { errors } = await response.json();
    return { errors };
  }
  return await response.json();
};
export default getLibraryStats;
