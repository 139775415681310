export const shortenTableEntry = (entry, numberOfCharacters) =>
  entry && (entry.length > numberOfCharacters)
    ? `${entry.substr(0, numberOfCharacters)}...`
    : entry;

export const formatAddressMiddle = (address) =>
  address.length > 18
    ? `${address.substr(0, 6)}...${address.substr(
      address.length - 6,
      address.length
    )}`
    : address;

export const prepareStringForDisplay = (str) => {
  try {
    str = decodeURIComponent(str?.replace(/\+/g, " "));
    return str;
  } catch (e) {
    // if error caught then string is not to be decoded and just return it
    return str;
  }
};

export const getDateTime = (date) => {
  const formattedDate = new Date(date);
  if (date !== null)
    return `${formattedDate.toLocaleDateString()} ${formattedDate.toLocaleTimeString()}`;
  else return date;
};

export const getShortDateTime = (theDate) => {
  const formattedDate = new Date(theDate);
  let date = formattedDate.toLocaleDateString();
  let time = formattedDate.toLocaleTimeString();
  return { date, time };
};

export const getShortAddresName = (address) => {
  // if starts wiuth "Wydawnictwo" shorten to "Wyd."
  if (address.startsWith("Wydawnictwo")) {
    return "Wyd." + address.substring(11);
  }
  // if first word is "Bilbiotek" shorten to "Bibl."
  if (address.startsWith("Biblioteka")) {
    // if second word is "Publiczna" shorten to "Bibl. Publ."
    if (address.startsWith("Biblioteka Publiczna")) {
      return "Bibl. Publ." + address.substring(20);
    } else {
      return "Bibl." + address.substring(10);
    }

  } else {
    if (address.length > 24) {
      address = address.slice(0, 24) + "...";
    }
    return address;
  }
};