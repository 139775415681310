import { SERVER_URL } from "../utils/constants";

const getBorrowedHistory = async (libAddress, userAddress) => {

  // libAddress = 'jjbqyitwzsvjzeez85a9rf8f4n3u6wk'
  // userAddress = 'pvkm32neqi685zg6ir3diwy4ixp5h4e9'

  const response = await fetch(
    `${SERVER_URL}/getBorrowedHistory?libAddress=${libAddress}&userAddress=${userAddress}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 400) {
    const { errors } = await response.json();
    return null
  }
  return await response.json();
};
export default getBorrowedHistory;
