import { withRouter } from "react-router";
import Background from "../components/Background";
import TransactionsTable from "../components/TransactionsTable";
import NavTopBar from "../components/NavTopBar";
import PlainContainer from "../components/PlainContainer";
import Footer from "../components/Footer";

const TransactionsView = ({ history }) => {
  return (
    <>
      <NavTopBar />
      <Background height="45vh" />

      <PlainContainer
        widthInVw={95}
        heightInVh={'auto'}
        customStyles={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          paddingBottom: "0px",
        }}
      >
        <TransactionsTable />
      </PlainContainer>
      <Footer />
    </>
  );
};

export default withRouter(TransactionsView);
