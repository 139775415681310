import React, { useMemo } from "react";
import { withRouter } from "react-router-dom";
import { useTable, useSortBy, usePagination } from "react-table";
import TableHeaders from "./TableHeaders";
import TableBody from "./TableBody";
import TableControls from "./TableControls";

import OverTableButtons from "../components/OverTableButtons";
import PlainContainer from "../components/PlainContainer";

//let users = require("../mock/mock_audiobooks_view.json");

const col = [
  {
    Header: "Kategoria",
    accessor: "category",
    columnType: "col-sm-4",
  },
  {
    Header: "Wypożyczeń",
    accessor: "count",
    columnType: "col-sm-3",
  },
];

const BorrowsTable = ({ value }) => {

  const columns = useMemo(() => col, []);
  const data = useMemo(() => value, [value]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageOptions,
    state,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    gotoPage,
    pageCount,
  } = useTable(
    { columns, data, initialState: { pageSize: 18 }, autoResetSortBy: false },
    useSortBy,
    usePagination
  );
  const { pageIndex } = state;

  return (
    <div>
      <OverTableButtons
        widthInVw={95}
        heightInVh={4.1}
        customStyles={{ top: "15.5vh" }}
      >
      </OverTableButtons>
      <PlainContainer
        widthInVw={95}
        heightInVh={'auto'}

        customStyles={{
          top: "19vh",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          paddingBottom: "0px",
          paddingLeft: "2%",
        }}
      >
        <div
          className="container"
          {...getTableProps()}
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        >
          {
            <>
              <TableHeaders headerGroups={headerGroups} />
              <TableBody
                {...getTableBodyProps()}
                rows={page}
                prepareRow={prepareRow}
                maxHeight={"67vh"}
              />
              <TableControls
                pageOptions={pageOptions}
                nextPage={nextPage}
                previousPage={previousPage}
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                pageCount={pageCount}
              />
            </>
          }
        </div>
      </PlainContainer>
    </div>
  );
};

export default withRouter(BorrowsTable);