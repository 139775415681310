import loginUserInLibrarian from "../../api/loginUserInLibrarian";
import { generateSessionId } from "../../utils/generateSessionId";
import store from "../../store";

export const onLoginUserInLibrarian = async (userData) => {
  const { username, password, history } = userData;

  const sessionId = generateSessionId();
  await loginUserInLibrarian({
    username,
    password,
    sessionId,
  })
    .then((res) => {
      if (res.user === null) {
        throw new Error("not_found");
      }

      store.dispatch({
        type: "LOG_IN_USER",
        user: {
          username: res.user.username,
          permissions: res.user.industry,
          business_type: res.user.business_type,
          industry: res.user.industry,
          libAddress: res.user.public_address,
          session: JSON.stringify(sessionId),
          token_id: res.user.token_id,
          jwtToken: res.user.jwtToken,
          libId: res.user.libId
        },
      });
      if (res.user.business_type === "publisher" || res.user.business_type === "publisherEmployee") {
        history.push("/tokens");
      } else {
        history.push("/users");
      }
      return res;
    })
    .catch((error) => {
      return false;
    });
};
