import React, { useState } from "react";
import getLibraryByFilter from "../../api/getLibraryByFilter";
import Modal from "react-modal";
import "./SearchLibrary.scss";

const SearchLibrary = ({ show, cancelAction, setLibraryAction }) => {
  const [searchedString, setSearchedString] = useState("");
  const [voivodeship, setVoivodeship] = useState("1");

  const [librariesList, setLibrariesList] = useState([]);

  const handleSearch = async (value) => {
    setSearchedString(value);
    if (value !== null && value !== "" && value !== undefined) {
      await getLibraryByFilter(value, voivodeship).then((response) => {
        if (response) {
          setLibrariesList(response);
        }
      });
    } else {
      setLibrariesList([]);
    }
  };

  const handleSetLibrary = ({ library }) => {
    setLibraryAction({
      industry: library.libraryName,
      publicKey: library.publicKey,
    });
    cancelAction(false);
  };

  return (
    <Modal
      isOpen={show}
      onRequestClose={cancelAction}
      style={{
        content: {
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: "2px 9px 15px 3px rgba(0,0,0,0.16)",
        },
        overlay: {
          backgroundColor: "rgba(255,255,255,0.4)",
        },
      }}
      contentLabel="Choose library modal"
    >
      <div className={(show ? "d-block" : "d-none") + " search-wrapper"}>
        <div className="search-bar-container">
          <div className="container">
            <button className="btn close-btn" onClick={cancelAction}>
              x
            </button>

            <form class="row">
              <div className="search-input col-9">
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder="Wpisz nazwę biblioteki..."
                  autoFocus
                />
              </div>

              <div className="search-select col-3">
                <select
                  className="form-control"
                  name="voivodeship"
                  id="voivodeship"
                  onChange={(e) => setVoivodeship(e.target.value)}
                >
                  <option value="1">Dolnośląskie</option>
                  <option value="2">Kujawsko-Pomorskie</option>
                  <option value="3">Lubelskie</option>
                  <option value="4">Lubuskie</option>
                  <option value="5">Łódzkie</option>
                  <option value="6">Małopolskie</option>
                  <option value="7">Mazowieckie</option>
                  <option value="8">Opolskie</option>
                  <option value="9">Podkarpackie</option>
                  <option value="10">Podlaskie</option>
                  <option value="11">Pomorskie</option>
                  <option value="12">Śląskie</option>
                  <option value="13">Świętokrzyskie</option>
                  <option value="14">Warmińsko-Mazurskie</option>
                  <option value="15">Wielkopolskie</option>
                  <option value="16">Zachodniopomorskie</option>
                </select>
              </div>
            </form>
          </div>

          {/* <span className="text-muted">
        Znaleziono: 0
    </span> */}

          <div className="search-results-container">
            <ul>
              {librariesList &&
                librariesList.map((library) => (
                  <li
                    key={library.publicKey}
                    className="search-result-item"
                    onClick={() => handleSetLibrary({ library })}
                  >
                    {library.libraryName}
                  </li>
                ))}
            </ul>

          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SearchLibrary;
