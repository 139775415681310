import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import TableHeaders from "./TableHeaders";
import TableBody from "./TableBody";
import TableControls from "./TableControls";
import Button from "./Button";
import { withRouter } from "react-router";
import getPublisherTokens from "../api/getPublisherTokens";
import { useSelector } from "react-redux";
import Spinner from "./Spinner/Spinner";
import OverTableButtons from "../components/OverTableButtons";
import store from "../store";
import TableSearch from "../components/TableSearch";
import StatusFilter from "./StatusFilter";
import PlainContainer from "../components/PlainContainer";
import { SEARCH_DEBOUNCE_TIME } from "../utils/constants";
import { debounce } from "../utils/debounce";

const TokensTable = ({ history }) => {
  const col = useMemo(() => [
    {
      Header: "ID",
      accessor: "token_id",
      columnType: "col-sm-1",
    },
    {
      Header: "Format",
      accessor: "format",
      columnType: "col-sm-1",
      Cell: ({ value }) => {
        switch (value) {
          case "AUDIOB":
            value = "Audiobook";
            break;
          default:
            value = "?";
            break;
        }
        return value;
      }
    },
    {
      Header: "Symbol",
      accessor: "token_symbol",
      columnType: "col-sm-1",
    },
    {
      Header: "Tytuł",
      accessor: "name",
      columnType: "col-sm-5",
    },

    {
      Header: "Utworzone",
      accessor: "mint",
      columnType: "col-sm-1",
    },
    {
      Header: "Dostępne",
      accessor: "balance",
      columnType: "col-sm-1",
    },
    {
      Header: "Wypożyczone",
      accessor: "borrowNow",
      columnType: "col-sm-1",
    },
    // {
    //   id: "token-disable-button",
    //   columnType: "col",
    //   Header: "",
    //   accessor: "user_id",
    //   Cell: ({ value }) => {
    //     return (
    //       <div className="float-right" >
    //         <Button
    //           text="..."
    //           // onClick={() => editPublisher(value)}
    //           styleOptions={{
    //             padding: "0.35rem 1rem",
    //             fontSize: "0.85rem",
    //           }}
    //         />
    //       </div>
    //     );
    //   },
    // },
  ], []);

  const [tokens, setTokens] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFiltered] = useState([]);
  const filterByType = useSelector(
    (state) => state.showTokenType.state
  );

  const [loading, setLoading] = useState(true);
  const loggedUser = useSelector((state) => state.authentication.user);



  const getTokens = useCallback(async () => {
    let response;
    response = await getPublisherTokens(loggedUser.libAddress);    // legacy naming
    setTokens(response);
    setFiltered(response);
    setLoading(false);
  }, [loggedUser.libAddress]);

  useEffect(() => {
    getTokens();
  }, [getTokens]);

  const columns = useMemo(() => col, [col]);
  const data = useMemo(() => filteredData, [filteredData,]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageOptions,
    state,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    gotoPage,
    pageCount,
  } = useTable(
    {
      columns, data, initialState: {
        pageSize: 16
      },
      autoResetSortBy: false
    },
    useSortBy,
    usePagination
  );
  const { pageIndex } = state;

  const handleChange = (event) => {
    setSearchInput(event.target.value);
  };

  const changeTypeFilter = () => {
    if (filterByType === false)
      store.dispatch({ type: "DONT_SHOW_AUDIOBOOKS" });
    else
      store.dispatch({ type: "SHOW_AUDIOBOOKS" });
  };

  const filteredByType = useCallback((data) => {
    if (filterByType) {
      const filtered = data.filter((el) => el.token_type === "AUDIOB");
      return filtered;
    } else {
      return data;
    }
  }, [filterByType]);

  useEffect(() => {
  }, [filterByType]);

  useEffect(() => {

    const filterTransactions = () => {
      if (searchInput.length > 2) {
        let pattern = searchInput.toLocaleLowerCase();
        let results = tokens.filter((value) => {
          if (
            value !== undefined
          ) {
            return (
              value.token_id.toString().includes(pattern) ||
              value.name.toLowerCase().includes(pattern) ||
              value.token_symbol.toLowerCase().includes(pattern)
            );
          } else {
            return null;
          }
        });
        setFiltered(filteredByType(results));
      } else {
        setFiltered(filteredByType(tokens));
      }
    };
    debounce(filterTransactions, SEARCH_DEBOUNCE_TIME)();
  }, [filteredByType, searchInput, tokens]);

  if (tokens.length === 0) {
    return null; // Render nothing if there are no tokens
  }

  return (
    <div>
      <OverTableButtons
        widthInVw={95}
        heightInVh={4.1}
        customStyles={{ top: "15.5vh" }}
      >
        <Button
          text=
          "Odśwież"
          isDisabled={false}
          styleOptions={{
            float: "right",
            boxShadow: "2px 4px 18px -15px rgba(255,0,0,0.31)",
          }}
          onClick={() => {
            setLoading(true);
            getTokens();
          }}
        />

        <TableSearch
          name="searchInputField"
          value={searchInput || ""}
          onChange={handleChange}
        />
        <div>
          <StatusFilter
            text={"Audiobooki"}
            width={"100px"}
            value={filterByType}
            onChange={changeTypeFilter}
          />
        </div>

      </OverTableButtons>
      <PlainContainer
        widthInVw={95}

        customStyles={{
          top: "19vh",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          paddingBottom: "0px",
        }}
      >
        <div
          className="container"
          {...getTableProps()}
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        >
          {!loading ?
            <>
              <TableHeaders headerGroups={headerGroups} />
              <TableBody
                {...getTableBodyProps()}
                rows={page}
                prepareRow={prepareRow}

              // maxHeight={"67vh"}
              />
              <TableControls
                pageOptions={pageOptions}
                nextPage={nextPage}
                previousPage={previousPage}
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                pageCount={pageCount}
              />
            </>
            : <div style={{ textAlign: "-webkit-center" }}>
              <Spinner customStyles={{ fontSize: "30px", margin: "40px" }} />
            </div>
          }
        </div>
      </PlainContainer>
    </div>

  );
};

export default withRouter(TokensTable);
