import { SERVER_URL } from "../utils/constants";

const getLibraryByFilter = async (searchedString, voivodeship) => {

  const response = await fetch(
    `${SERVER_URL}/getLibraryByFilter?searchedString=${searchedString}&voivodeship=${voivodeship}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 400) {
    const { errors } = await response.json();
    return errors
  }
  return await response.json();
};
export default getLibraryByFilter;
