import { SERVER_URL } from '../utils/constants'

const registerLibEmployee = async (employeePayload) => {

  const response = await fetch(
    `${SERVER_URL}/registerLibEmployee`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(employeePayload),
  }
  )
  if (response.status === 200) {
    return response.status
  }
  if (response.status === 400) {
    const { errors } = await response.json()
    return { errors }
  }
  return await response.json()
}
export default registerLibEmployee
