const TableSearch = (props) => {
  return (
    <input
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      className="table-search"
      placeholder="Wyszukaj... (min. 3 znaki)"
    ></input>
  );
};

export default TableSearch;
